<template>
    <div>

        <div class="page-title">适宜唇色检测报告</div>

        <a-table :columns="table.columns"
                 :rowKey="record => record.id || record.key"
                 :data-source="table.data"
                 :pagination="table.pagination"
                 @change="tableChanged"
                 :locale="{emptyText:'暂无检测报告'}"
                 class="members-table"
                 :loading="table.loading"
        >
            <div slot="time" slot-scope="time">
                {{ $formatTime(time) }}
            </div>

            <div slot-scope="name,order" slot="name">
                <router-link class="member-name" :to="`/ast/members/${order.ast_member.id}`">
                    {{ order.ast_member.mark_name || order.ast_member.name }}
                </router-link>
            </div>


            <div class="avatar" slot="avatar" slot-scope="avatar,order">
                <template v-if="order.ast_member.avatar">
                    <img :src="order.ast_member.avatar" alt="">
                </template>
                <template v-else>
                    <img src="/assets/icons/dfava.png" alt="">
                </template>
            </div>

            <div slot="pdf">
                <span>未生成</span>
            </div>

            <div slot="op" slot-scope="val,item">
                <a @click="generate(item)">查看报告</a>
            </div>

        </a-table>

        <a-modal v-model="showQrcodeModal" title="微信扫一扫查看上色效果">
            <img width="150px" :src="qrcode" alt="">
        </a-modal>

    </div>
</template>

<script>
import api from "@/repo/api";

export default {
    name: "Lipstick",
    data() {
        return {
            table: {
                columns: [
                    {
                        title: 'ID',
                        dataIndex: 'id',
                        width: '110px',
                    },
                    {
                        title: '时间',
                        dataIndex: 'paid_at',
                        scopedSlots: {customRender: 'time'},
                    },
                    {
                        title: '用户ID',
                        dataIndex: 'ast_member.member_id',
                    },
                    {
                        title: '头像',
                        dataIndex: 'avatar',
                        scopedSlots: {customRender: 'avatar'},
                    },
                    {
                        title: '昵称/备注名',
                        dataIndex: 'name',
                        scopedSlots: {customRender: 'name'},
                    },
                    {
                        title: '检测报告',
                        dataIndex: 'pdf',
                        scopedSlots: {customRender: 'pdf'},
                    },
                    {
                        title: '操作',
                        dataIndex: 'op',
                        scopedSlots: {customRender: 'op'},
                    }
                ],
                loading: true,
                pagination: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                    showTotal: (total, range) => {
                        return `当前${range[0]}-${range[1]}，共 ${total} 个会员`;
                    },
                },
                data: []
            },
            query: {},
            qrcode: '',
            showQrcodeModal: false,
        }
    },
    mounted() {
        this.getFirstList()
    },
    methods: {
        generate(item) {
            this.qrcode = this.$qrcode(`/packagesA/lipstick/colors?report_id=${item.id}`);
            this.showQrcodeModal = true
        },
        getFirstList() {
            this.query.page = 1
            this.getList()
        },
        getList() {

            this.table.loading = true
            this.query.status = this.status

            api.get('/ast-lipstick/reports', this.query, (data) => {
                this.table.data = data.data.data
                this.table.pagination.current = data.data.current_page
                this.table.pagination.total = data.data.total
                this.table.pagination.pageSize = data.data.per_page

                this.table.loading = false

            })
        },
        tableChanged(pagination) {
            this.query.page = pagination.current
            this.getList()
        }
    }
}
</script>

<style scoped lang="less">
.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;

    img {
        width: 100%;
    }
}
</style>